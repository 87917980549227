import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import renderBlock from '../utils/render-block'

import SEO from '../components/seo'

class IndexPage extends Component {

  render() {
    const { home } = this.props.data
    const content = home.acf.content_blocks_page || []
    return (
      <>
        <SEO title={he.decode(home.yoast_meta.yoast_wpseo_title)} description={home.yoast_meta.yoast_wpseo_metadesc} />
        { content.map((el, i) => renderBlock(el.__typename, el, i)) }
      </>
    )
  }
}

export default IndexPage

export const homeQuery = graphql`
  query {
    home: wordpressPage(wordpress_id: {eq: 5}) {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      title
      acf {
        content_blocks_page {
          __typename
          ... on WordPressAcf_landing_splash {
            homepage
            heading {
              title
              background {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_small_split_content {
            title
            content
            link_text
            link_url
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_video_area {
            title
            content
            video
            video_poster {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            testimonials {
              testimonial {
                acf {
                  name
                  location
                  customer_type
                  testimony
                }
              }
            }
          }
        }
      }
    }
  }
`
